<template>
  <ion-page>
    <!-- <ion-header>
      <ion-toolbar>
        <ion-title>CENDI</ion-title>
      </ion-toolbar>
    </ion-header> -->

    <ion-content>
    <ion-card>
      <ion-card-header>
        <ion-card-title>{{ alias }}</ion-card-title>
      </ion-card-header>
    </ion-card>

    <ion-grid>
      <ion-row>
        <ion-col size="12" @click="() => router.push(`/form/?token=${token}`)">
          <img :src="require('@/images/agregocuestionario.png')">
        </ion-col>
        <ion-col @click="open">
          <img :src="require('@/images/verqr.png')">
        </ion-col>
        <ion-col size="12">
          <ion-button expand="block" @click="logout">SALIR</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonRow, IonCol, IonCardHeader, IonCardTitle, IonCardSubtitle } from '@ionic/vue';
import { useRouter } from 'vue-router';
import router from "../router";
import { TokenUtils } from '@/utils/TokenUtils';

export default {
  name: "menu",
  components: {
    IonPage, IonRow, IonCol, IonCardHeader, IonCardTitle
  },
  data: () => ({
    alias: null,
    token: null,
    familyId: null,
  }),
  setup() {
    const router = useRouter();
    return { router };
  },
  methods: {
    logout() {
      router.push('/login');
    },
    open() {
      window.open(`https://api.ingressify.mx/media/documents/family_badges_${this.familyId}.pdf`, '_system', 'location=yes');
      return false;
    }
  },
  mounted() {
    this.token = this.$route.query.token;
    console.log('token', this.token);
    TokenUtils.setToken(this.token);
    this.tokenDecode = TokenUtils.getJwtData();
    console.log('tokenDecode', this.tokenDecode);
    this.alias = this.tokenDecode.alias;
    this.familyId = this.tokenDecode.sub;
  },
}
</script>

<style scoped>

</style>
